<template>
    <div>
        <b-container class="login">

            <b-row align-h="center" class="m-2">
                <b-img class="logo" src="\logo-full.png"/>
            </b-row>
            <div class="clearfix"></div>
            <b-row>
                <b-col md="4" class="mx-auto">
                    <b-card>
                        <b-form @submit="onSubmit">
                            <b-alert :show="error!=''" variant="danger" dismissible @dismissed="dismissed">{{error}}</b-alert>
                            <b-form-group label="Username:" label-for="username">
                                <b-form-input id="username" type="text" v-model="form.username" required placeholder="Username" :disabled="loading"></b-form-input>
                            </b-form-group>
                            <b-form-group label="Password:" label-for="password">
                                <b-form-input id="password" type="password" v-model="form.password" required placeholder="Password" :disabled="loading"></b-form-input>
                            </b-form-group>
                            <b-button type="submit" variant="primary" :disabled="loading">Masuk</b-button>
                        </b-form>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>

export default {
    middleware: 'notAuthorized',
    data(){
        return({
            form: {
                username: '',
                password: '',
            },
            error:'',
            loading : true
        })
    },
    mounted(){
        this.loading=false;
    },
    methods:{
        async onSubmit (evt) {
            evt.preventDefault();
            this.loading=true;
            let resp = await this.$store.dispatch('login',this.form);
            if(resp && resp.status){
                return this.$router.push('/admin/categories');
            }
            this.loading=false;
            this.error = resp.error.message ? resp.error.message : 'Internal Server Error';
            return false;
        },
        dismissed(){
            this.error = '';
        }
    }
}
</script>

